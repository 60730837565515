import mediaTitle from '../images/media-v4.png';
import MediaItem from './media-item';

function MediaSection() {
  return (
	<div>
	 	<a className="anchor" id="band-media"></a>
  		<div id="band-songs" className="container-fluid bg-black">  		
		  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
	
					<div>
						<div id="media-title" className="text-center">
							<img id="media-title" className="img-fluid" src={mediaTitle} alt="Media" data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>
						</div>
						
						<div className="row" id="band-songs-content">
							<div className="card-deck">
								<MediaItem 
									title = "Snake Skin"
									description = "Open your ears and let the sweet poison run thru your veins."
									link = "https://www.youtube.com/embed/1yQdsEcRrO8"
								/>
								
								<MediaItem 
									title = "Bear Hug"
									description = "When life is tough and there is no one to help, will you give up?"
									link = "https://www.youtube.com/embed/DICUQAlZc60"
								/>
								
								<MediaItem 
									title = "Screaming for Mercy"
									description = "Fasten your seatbelts and prepare to the ride with the underdogs of the music scene."
									link = "https://www.youtube.com/embed/5NhmXrVJBaU"
								/>						
							</div>
							
						</div>
					</div>
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	</div>
  	</div>

	</div>
  );
}

export default MediaSection;
